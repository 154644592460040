<template>
<main class="main">
  <MyHeader />
  <section class="section research">
    <div class="wrap">
      <div class="content-area" style="display: flex;">
        <!-- Column 1: Welcome Content -->
        <div class="welcome-content" style="flex: 1; padding-right: 20px;">
          <h2>Welcome to HiMoRNA!</h2>
          <p class="text">HiMoRNA (Histone-Modifying RNA) database is a resource containing correlated lncRNA–epigenetic changes in specific genomic locations genome-wide. HiMoRNA integrates a large amount of multi-omics data to characterize the effects of lncRNA on epigenetic modifications and gene expression.</p>
          <h2>Functionality</h2>
          <div class="content" style="text-align: left;">
              <ul class="text">
                <li>⬤ Browsing, searching, and retrieving of lncRNAs associated with epigenetic profiles of various chromatin loci</li>
                <li>⬤ Download 
                  <span class="tooltip"><a href="/data/himorna_database_dump_peaks_and_correlations.tar.gz">full database</a><span class="tooltiptext">Database dump (1 GB) includes columns: HistoneModification, lncRNA, lncRNAEnsembl, PeakID, PeakChr, PeakStart, PeakEnd, AssociatedGene, AssociatedGeneEnsembl, Correlation </span></span>
                  , <span class="tooltip"><a href="/data/GeneTable.csv">Gene table</a><span class="tooltiptext">FANTOM and Ensembl</span></span>
                  , <span class="tooltip"><a href="/data/lncRNA_CorrespondenceTable.csv">lncRNA correspondence table</a><span class="tooltiptext">Correspondence with RNAChrom</span></span>
                </li>
                <li>⬤ Integration of HiMoRNA and <a href="https://rnachrom2.bioinf.fbb.msu.ru/" target="_blank" rel="noopener noreferrer">RNA-Chrom DB</a></li>
              </ul>
            </div>
          <!-- <p class="text" style="width:70%">To help researchers perform custom analysis using the collected dataset, we provide the whole database in CSV format.</p> -->
          <div>
            <a href="/search" class="button orange">
              <p>Search page</p>
            </a>
          </div>
        </div>

        <!-- Column 2: MyTable Component -->
        <div style="flex: 1;">
          <template v-if="getTable || research">
            <div class="wrap">
              <MyTable :max="11" :data="getTable?.table || research?.table" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
  <MyFooter />
</main>
</template>



<script>
import MyHeader from '@/components/MyHeader';
import MyFooter from '@/components/MyFooter';
import MyTable from '@/components/MyTable';
export default {
  name: 'dashboard',
  components: {
    MyHeader,
    MyTable,
    MyFooter
  },
  data() {
    return {
      research: null
    }
  },
  computed: {
    getTable () {
      return this.$store.getters.getDashboardTable
    }
  },
  async mounted () {
    this.research = await this.$store.dispatch('getDashboard')
  },
  methods: {
    downloadDatabaseFile() {
      window.open('/data/himorna_database_dump_peaks_and_correlations.tar.gz')
    }
  }
}

</script>

<style>
    .tooltip {
        position: relative;
        display: inline-block;
        cursor: pointer; /* Adds a pointer cursor on hover */
    }

    .tooltip .tooltiptext {
        visibility: hidden;
        width: 200px; /* Updated width */
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 8px;
        margin: 5px 0;

        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -100px; /* Updated margin-left */
        opacity: 0;
        transition: opacity 0.3s, visibility 0.3s;
        box-shadow: 0 2px 4px rgba(0,0,0,0.2);
        font-size: 15px;
        line-height: 13px;
    }

    .tooltip .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
    }

    .tooltip:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
    }
</style>

        <!--<div class="scroll-down">
          <p class="scroll-down__text">Scroll down</p>
          <img src="@/assets/images/scroll-down.svg" width="40px">
        </div>-->

        <!-- <div class="welcome-content"> -->
          <!--<h2>Welcome to website of scientific experiment</h2>

          <p class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
          <p class="text">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>-->

                  <!-- <h2 class="section-title">Research results</h2>
                  <p class="section-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>-->

<!--           
          <Grid container spacing={0}>
            <Grid item xs={6} >
              <li></li>
            </Grid>
            <Grid item xs={6}>
              <div>
                <li></li>
              </div>
            </Grid>
          </Grid> -->

<!-- 
<template>
  <div class="grid-container">
    <div class="grid-item" style="flex-basis: 50%;">
      <ul>
        <li></li>
      </ul>
    </div>
    <div class="grid-item" style="flex-basis: 50%;">
      <div>
        <ul>
          <li></li>
        </ul>
      </div>
    </div>
  </div>
</template>



<style>
.grid-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}
.grid-item {
  padding: 8px;
}
</style>
 -->


      <!-- <div class="button orange" @click="downloadDatabaseFile()">
                    <p>Download Database</p>
                </div> -->

    <!-- <section class="section">
      <div class="wrap">
        <h2 class="section-title">How we did it?</h2>
        <p class="section-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
        <div class="section-block">
          <div class="section-content">
            <h2>1. Welcome to website of scientific experiment</h2>
            <p class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            <p class="text">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <router-link tag="div" class="button orange" to="/search">
              <p>Search</p>
            </router-link>
          </div>
          <div class="section-image" :style="{backgroundImage: 'url(' + require('@/assets/images/section-1.jpg') + ')'}"></div>
        </div>
        <div class="section-block">
          <div class="section-content">
            <h2>2. Welcome to website of scientific experiment</h2>
            <p class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            <p class="text">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <router-link tag="div" class="button orange" to="/search">
              <p>Search</p>
            </router-link>
          </div>
          <div class="section-image" :style="{ backgroundImage: 'url(~@/assets/images/section-1.jpg)' }"></div>
        </div>
      </div>
    </section>
    <section class="section research">
      <div class="wrap">
        <h2 class="section-title">Research results</h2>
        <p class="section-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>

        <MyTable :max="11" v-if="getTable || research" :data="getTable.table || research.table" />
      </div>
    </section>-->